/* eslint-disable no-unused-vars */
import { useEffect, React } from 'react'
import {
    unstable_HistoryRouter as HistoryRouter,
    Routes,
    Route,
    Outlet,
    Navigate
} from 'react-router-dom'
import { ProtectedRoutes } from './ProtectedRoutes'
import { NetworkNavbar } from './NetworkNavbar'
import { useMatomo } from '@jonkoops/matomo-tracker-react'
import {
    Login,
    Home,
    Cards,
    CardInventory,
    CardSale,
    Village,
    Ranking,
    Deposits,
    VillageSelection,
    VillageClientDetails,
    VillageClient,
    ClientPaymentHistory,
    VillagePrevClientDetails,
    Calcapp,
    Visit,
    GPSWarning,
    EnrollmentClientDetails,
    ClientSearch,
    ClientSearchResult,
    PaymentNotification,
    PackageSelectionPage,
    FarmerIdQr,
    PaymentSelect
} from '../pages'

import { customHistory } from './customHistory'
import { setTheme, getConnectUser } from 'helpers'
import EnrollmentBase from 'pages/enrollment/v3/EnrollmentBase'

export const ConnectRoutes = () => {
    if (process.env.REACT_APP_MATOMO_URL) {
        const { trackPageView, pushInstruction } = useMatomo()
        let username
        useEffect(() => {
            const unlisten = customHistory.listen(() => {
                const user = getConnectUser()
                // As soon as the user is logged in we want to set the username as userID for analytics
                if (username !== user?.username) {
                    username = user?.username
                    pushInstruction('setUserId', user.username)
                }
                const splittedPath = customHistory.location.pathname.split('/')
                const pageName = splittedPath[1]
                // Ignore history change when navigating to village without an SFID
                // such as /village/villageSFID
                // Because every time we navigate to the village page there is an extra call
                if (pageName !== 'village' || splittedPath.length > 1) {
                    trackPageView({
                        documentTitle:
                            customHistory.location.pathname.split('/')[1]
                    })
                }
            })

            return () => {
                unlisten()
            }
        }, [customHistory])
    }
    useEffect(() => {
        const theme = localStorage.getItem('connect_theme')
        if (theme) {
            setTheme(theme)
        }
    }, [])

    return (
        <HistoryRouter history={customHistory}>
            {<NetworkNavbar />}
            <Routes>
                <Route path="/" element={<ProtectedRoutes />}>
                    <Route path="/" element={<Outlet />}>
                        <Route
                            path="/"
                            element={<Navigate replace to="home" />}
                        />
                        <Route path="home" element={<Home />} />
                        <Route path="home" element={<Outlet />}>
                            <Route path="ranking" element={<Ranking />} />
                        </Route>
                        <Route path="home" element={<Outlet />}>
                            <Route path="deposits" element={<Deposits />} />
                        </Route>
                        <Route path="cards" element={<Cards />} />
                        <Route path="cards" element={<Outlet />}>
                            <Route
                                path="card-inventory"
                                element={<CardInventory />}
                            />
                            <Route path="card-sale" element={<CardSale />} />
                        </Route>
                        <Route path="village" element={<Village />} />
                        <Route path="village" element={<Outlet />}>
                            <Route
                                path="select-village"
                                element={<VillageSelection />}
                            />
                            <Route
                                path=":villageId"
                                element={<VillageClient />}
                            />
                            <Route path=":villageId" element={<Outlet />}>
                                <Route
                                    path="client/:clientId"
                                    element={<VillageClientDetails />}
                                />
                                <Route
                                    path="client/:clientId"
                                    element={<Outlet />}
                                >
                                    <Route
                                        path="payment-history"
                                        element={<ClientPaymentHistory />}
                                    />
                                    <Route
                                        path="previous"
                                        element={<VillagePrevClientDetails />}
                                    />
                                </Route>
                            </Route>
                        </Route>
                        <Route path="enrollment" element={<Outlet />}>
                            <Route
                                path="package-selection"
                                element={<PackageSelectionPage />}
                            />
                            <Route
                                path="client-details"
                                element={<EnrollmentClientDetails />}
                            />
                        </Route>
                        <Route path="calcapp" element={<Calcapp />} />
                        <Route path="gpswarning" element={<GPSWarning />} />
                        <Route path="visit" element={<Visit />} />
                        <Route
                            path="client-search"
                            element={<ClientSearch />}
                        />
                        <Route path="client-search" element={<Outlet />}>
                            <Route
                                path="result"
                                element={<ClientSearchResult />}
                            />
                        </Route>
                        <Route path="notification" element={<Outlet />}>
                            <Route
                                path="payment-notification"
                                element={<PaymentNotification />}
                            />
                        </Route>
                        <Route
                            path="/enroll/:villageId"
                            element={<EnrollmentBase />}
                        />
                        <Route path="farmer-id" element={<FarmerIdQr />} />
                        <Route
                            path="payment-selection"
                            element={<PaymentSelect />}
                        />
                    </Route>
                </Route>
            </Routes>
            <Routes>
                <Route path="login" element={<Login />} />
            </Routes>
        </HistoryRouter>
    )
}
