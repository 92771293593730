import React, { useEffect, useRef, useState } from 'react'
import { CSSTransition } from 'react-transition-group'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { Card, Col, Container, Row, Spinner, TopBar } from 'components'
import {
    constants,
    amountFormatter,
    isSupportedTransaction,
    getConnectUser
} from 'helpers'
import { useBindDispatch } from 'hooks'
import { PaymentSummary } from 'pages/village/PaymentSummary'
import { PaymentRow } from 'pages/village/PaymentRow'
import { useSelector } from 'react-redux'

export const ClientPaymentHistory = () => {
    const [animate, setAnimate] = useState(false)
    const [animationList, setAnimationList] = useState(false)
    const { clientData } = useSelector((state) => state.villageClientList)
    const { enrollmentPayload } = useSelector((state) => state.updatePackages)
    const { loading: singleClientLoader, client: singleClient } = useSelector(
        (state) => state.singleClient
    )
    const { villageId: urlVillageId, clientId: urlClientCode } = useParams()
    const [searchParams] = useSearchParams()
    const reload = searchParams.get('reload')

    const nodeRef = useRef(null)

    const { t } = useTranslation()
    const { navbarHandler, updateSingleClientBalAndSmsHandler } =
        useBindDispatch()
    const {
        fullname,
        all_enrollment: allEnrollment,
        balance,
        last_voucher_date: lastVoucherDate,
        smss = [],
        duplicate
    } = JSON.parse(localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE))
    const [balanceSms, setBalanceSms] = useState({
        balance: balance || 0,
        smss: smss || []
    })
    const { country } = getConnectUser()
    const navigate = useNavigate()

    useEffect(() => {
        setAnimate(true)
        navbarHandler(constants.SHOW_NAVBAR)
    }, [])

    useEffect(() => {
        if (clientData?.client_code) {
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify({
                    ...clientData,
                    fullname: `${clientData.firstname} ${clientData.lastname}`,
                    duplicate: duplicate || clientData.duplicate
                })
            )
            setBalanceSms({
                balance: clientData.balance || 0,
                smss: clientData.smss || []
            })
        }
    }, [clientData])

    useEffect(() => {
        if (enrollmentPayload?.client_code) {
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify({
                    ...enrollmentPayload,
                    fullname: `${enrollmentPayload.firstname} ${enrollmentPayload.lastname}`
                })
            )
        }
    }, [enrollmentPayload])

    useEffect(() => {
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId: urlVillageId,
                clientId: urlClientCode
            })
        }
    }, [reload])

    useEffect(() => {
        if (singleClient.length > 0) {
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify({
                    ...singleClient[0],
                    all_enrollment: allEnrollment || 0,
                    smss,
                    fullname: `${singleClient[0].firstname} ${singleClient[0].lastname}`
                })
            )
            setBalanceSms({
                balance: singleClient[0]?.balance || 0,
                smss: singleClient[0].smss || []
            })
        }
    }, [singleClientLoader, singleClient])

    const paymentHistoryRender = (balanceSms.smss || [])
        .filter(isSupportedTransaction)
        .map((transaction, idx) => (
            <PaymentRow key={idx} country={country} transaction={transaction} />
        ))

    const backNavigationHandler = () => {
        navigate(`/village/${urlVillageId}/client/${urlClientCode}`)
    }

    return (
        <>
            <div className="header-bar" id="header-bar">
                <Container>
                    <TopBar back backNavigation={backNavigationHandler}>
                        <span className="client-payment-history--header">
                            {fullname}
                        </span>
                    </TopBar>
                </Container>
            </div>
            {singleClientLoader ? (
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="loading-wrapper">
                                <p className="loading-text">
                                    {t('fetchingPaymentLoader')}
                                </p>
                                <Spinner size="120" />
                            </div>
                        </Col>
                    </Row>
                </Container>
            ) : (
                <CSSTransition
                    unmountOnExit
                    timeout={constants.ANIMATION_TIMEOUT}
                    in={animate}
                    classNames="generic"
                    appear
                    onEnter={() => setAnimationList(true)}
                    nodeRef={nodeRef}
                >
                    <div
                        className="client-payment-parent page-wrapper"
                        data-testid="payment-history-card"
                        ref={nodeRef}
                    >
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <Card
                                        className={
                                            animationList
                                                ? 'height-100 animate-show-1 animate-showed-1'
                                                : 'height-100 animate-show-1'
                                        }
                                    >
                                        <div className="village-client-details-padding village-payment--main">
                                            <PaymentSummary
                                                allEnrollment={amountFormatter(
                                                    allEnrollment,
                                                    country
                                                )}
                                                balance={amountFormatter(
                                                    balanceSms.balance,
                                                    country
                                                )}
                                                paymentLeft={amountFormatter(
                                                    allEnrollment - balance,
                                                    country
                                                )}
                                                lastVoucherDate={
                                                    lastVoucherDate
                                                        ? t(
                                                              'fullDateMonthYear',
                                                              {
                                                                  date: new Date(
                                                                      lastVoucherDate
                                                                  )
                                                              }
                                                          )
                                                        : '-'
                                                }
                                                percentage={parseFloat(
                                                    (balanceSms.balance /
                                                        allEnrollment) *
                                                        100
                                                )}
                                            />
                                            <div className="client-payment--history-parent">
                                                <h2 className="client-payment--history-header">
                                                    {t('paymentHistory')}
                                                </h2>
                                                {paymentHistoryRender}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        </Container>
                    </div>
                </CSSTransition>
            )}
        </>
    )
}
