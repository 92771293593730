import { ConnectUserType } from './../helpers/appTypes'
import connectApi from '../api/connectApi'
import jwtDecode from 'jwt-decode'
import { constants, setTrainingMode, setTheme } from 'helpers'
import i18n from '../helpers/i18n'
import { sendMessageToWorker } from '../helpers/communication'
import { AuthInterface, AuthActionType, AuthEnum } from '../storeTypes'
import { Dispatch } from 'redux'
import * as Sentry from '@sentry/react'

const LOGIN_ERROR_MESSAGES = {
    401: 'login.invalidCredentials',
    403: 'login.notAuthorized'
} as { [key: number]: string }

export const loginAction = ({ username, password }: AuthInterface) => {
    const { LOGIN } = constants.endpoints
    return (dispatch: Dispatch<AuthActionType>) => {
        dispatch({ type: AuthEnum.LOGGING })
        connectApi
            .post(LOGIN, {
                username,
                password
            })
            .then((response) => {
                const { data, status } = response

                if (status >= 400) {
                    const message =
                        LOGIN_ERROR_MESSAGES[status] ||
                        'login.invalidCredentials'

                    return dispatch({
                        type: AuthEnum.LOGIN_FAIL,
                        payload: {
                            message,
                            statusCode: status
                        }
                    })
                }

                // decode jwt response
                const user: ConnectUserType = jwtDecode(data.access_token)
                user.token = data.access_token
                // save user in storage
                localStorage.setItem(
                    constants.CONNECT_USER,
                    JSON.stringify(user)
                )
                // Pass the user info to the service worker
                sendMessageToWorker({ 'user info': user })

                if (process.env.REACT_APP_SENTRY_DSN) {
                    Sentry.setUser({
                        id: user.user_id.toString(),
                        username: user.username
                    })
                }

                // set user language
                i18n.changeLanguage(user.language)
                dispatch({
                    type: AuthEnum.LOGIN_SUCCESS,
                    payload: { message: data.message, statusCode: status, user }
                })
                sendMessageToWorker({ 'sync open tabs': '' })
                // The set should already be set but this handles an edge case scenarion
                // with multiple tabs open
                const isTrainingModeOn =
                    localStorage.getItem('connect_training_mode') === 'true'
                setTheme(
                    isTrainingModeOn
                        ? constants.THEME_NAMES.TRAINING
                        : constants.THEME_NAMES.DEFAULT
                )
            })
            .catch(() => {
                dispatch({
                    type: AuthEnum.LOGIN_FAIL,
                    payload: {
                        message: 'login.invalidCredentials',
                        statusCode: 500
                    }
                })
            })
    }
}

export const logoutAction = () => {
    return (dispatch: Dispatch<AuthActionType>) => {
        // Erase user info from the service worker
        sendMessageToWorker({ 'user info': {} })
        setTrainingMode(false)
        localStorage.clear()
        dispatch({ type: AuthEnum.LOGOUT })
    }
}
