import React, { useEffect, useState, useContext } from 'react'
import { useSelector } from 'react-redux'
import {
    useLocation,
    useNavigate,
    useParams,
    useSearchParams
} from 'react-router-dom'
import {
    Card,
    Col,
    Container,
    Row,
    TopBar,
    CardClientDetails,
    Button,
    Spinner,
    Modal
} from '../../components'
import { useBackAction, useBindDispatch, useGetStorage } from '../../hooks'
import {
    amountFormatter,
    constants,
    isClientInfoComplete,
    isSupportedTransaction,
    routes,
    generateUuidv4,
    getConnectUser
} from '../../helpers'
import { useTranslation } from 'react-i18next'
import { ClientStatus as ClientStatusEnum } from '../../storeTypes/clientTypes'
import ClientStatus from '../../components/card/ClientStatus'
import { findPermission } from '../../helpers/findPermission'
import { ClientInteractions, InteractionOutcomes } from '../../types'
import { AppModalContext } from '../../components/appModal/AppModalProvider'
import { PaymentRow } from 'pages/village/PaymentRow'
import { ConfirmDuplicateFlagInteraction } from 'components/modal/ConfirmDuplicateFlagInteraction'

export const VillagePrevClientDetails = () => {
    const { t } = useTranslation()
    const { prevClientData } = useSelector(
        (state) => state.villagePreviousClientList
    )
    const { loading: singleClientLoader, client: singleClient } = useSelector(
        (state) => state.singleClient
    )
    const [searchParams] = useSearchParams()
    const reload = searchParams.get('reload')
    const fromURL = searchParams.get('from')

    const { displayAppModal: showInteractionFeedbackModal } =
        useContext(AppModalContext)
    const [prevClientDetails, setPrevClientDetails] = useState()
    const [displayModal, setDisplayModal] = useState(false)

    const {
        selectVillagePrevClientActionHandler,
        navbarHandler,
        openVillagePageHandler,
        updateSingleClientBalAndSmsHandler,
        clearSingleClientDataHandler,
        sendInteractionsActionHandler,
        clientDetailsEnrollmentHandler
    } = useBindDispatch()
    const { villageId, clientId } = useParams()
    const { pathname } = useLocation()
    const navigate = useNavigate()
    const { veVillages, country, username } = getConnectUser()
    // get village name
    const { name: villageName } = veVillages.find(
        ({ sf_id: sfId }) => sfId === villageId
    ) || { name: '' }

    const userPermissions = useGetStorage(constants.USER_PERMISSIONS)

    const localPrevClientDetails = useGetStorage(
        constants.CLIENT_DETAIL_LOCAL_STORE
    )

    const displayVisitButton =
        findPermission(userPermissions, constants.CAN_RECORD_HOME_VISIT) &&
        (prevClientDetails?.duplicate === false ||
            prevClientDetails?.duplicate === undefined)
    const canEnrollClientPackage = findPermission(
        userPermissions,
        constants.UPDATE_ORDER
    )

    // handles on device back button press or back swipe
    useBackAction(fromURL || `/village/${villageId}`)

    // show menu bar
    useEffect(() => {
        navbarHandler(constants.SHOW_NAVBAR)
        document.title = t('clientDetails.pageTitle')

        return () => {
            clearSingleClientDataHandler()
        }
    }, [])

    useEffect(() => {
        selectVillagePrevClientActionHandler({ clientId, villageId })
    }, [])

    useEffect(() => {
        if (reload) {
            updateSingleClientBalAndSmsHandler({
                villageId,
                clientId
            })
        }
    }, [reload])

    useEffect(() => {
        if (singleClient.length > 0) {
            const updatedClientData = {
                ...singleClient[0],
                fullname: `${singleClient[0].firstname} ${singleClient[0].lastname}`
            }
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(updatedClientData)
            )

            setPrevClientDetails(updatedClientData)
        }
    }, [singleClientLoader, singleClient])

    // locally store client details
    useEffect(() => {
        const prevClients = prevClientData && Object.keys(prevClientData)
        if (!localPrevClientDetails && prevClientData?.client_code) {
            setPrevClientDetails(prevClientData)
            localStorage.setItem(
                constants.CLIENT_DETAIL_LOCAL_STORE,
                JSON.stringify(prevClientData)
            )
        } else {
            const clientDetails = localPrevClientDetails
            setPrevClientDetails(clientDetails)
            if (prevClients?.length < 1) {
                if (villageName && clientId === clientDetails?.client_code) {
                    setPrevClientDetails(clientDetails)
                } else {
                    handleFailedPageNavigation()
                }
            }
        }
    }, [prevClientData])

    const openVisitPageHandler = () => {
        if (isClientInfoComplete(prevClientDetails)) {
            localStorage.setItem(
                constants.CLIENT_FOR_VISIT_STORE,
                JSON.stringify({
                    clientCode: prevClientDetails.client_code,
                    villageId: prevClientDetails.village_id || villageId,
                    clientName: prevClientDetails.firstname,
                    origin: constants.ORIGIN_PREV_CLIENT_DETAIL
                })
            )
            navigate('/visit')
        } else {
            modifyHandler('/visit')
        }
    }

    const handleFailedPageNavigation = () => {
        if (veVillages.length > 1) {
            return navigate('/village')
        }
        return navigate(`/village/${veVillages[0].sf_id}`)
    }

    const reEnrollmentHandler = () => {
        // include form type and village info in client data
        const localClientData = JSON.parse(
            localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE)
        )
        localClientData.village_name = villageName
        localClientData.village = villageId

        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...localClientData,
                form_type: 're-enrollment'
            })
        )
        return navigate(`/${routes.enroll}/${villageId}?from=${pathname}`)
    }

    const modifyHandler = (nextURL = '') => {
        // include form type and village info in client data
        const localClientData = JSON.parse(
            localStorage.getItem(constants.CLIENT_DETAIL_LOCAL_STORE)
        )
        localClientData.village_name = villageName
        localClientData.village = villageId

        localStorage.setItem(
            constants.CLIENT_FOR_ENROLLMENT_STORE,
            JSON.stringify({
                ...localClientData,
                form_type: 'modification',
                modification: constants.CLIENT_MODIFICATION_TYPES.DETAILS
            })
        )
        const next = nextURL ? `&next=${nextURL}` : ''
        return navigate(
            `/${routes.enroll}/${localClientData.village}?from=${pathname}${next}`
        )
    }

    const backNavigationHandler = () => {
        openVillagePageHandler({
            filterOptions: '',
            villageId,
            clientTab: constants.VILLAGE_PAGE_TABS.PREVIOUS_CLIENT_TAB
        })
        return navigate(fromURL || `/village/${villageId}`)
    }

    const markClientAsDuplicate = () => {
        clientDetailsEnrollmentHandler({
            clientDetailsPayload: {
                ...prevClientDetails,
                duplicate: true,
                village: prevClientDetails.village_id,
                form_type: 'modification',
                username,
                uuid: generateUuidv4(),
                date: new Date().toISOString()
            }
        })
        sendInteractionsActionHandler({
            client_code: clientId,
            user_id: username,
            outcome: InteractionOutcomes.DUPLICATE,
            client_status: prevClientDetails.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        const updatedClient = {
            ...prevClientDetails,
            duplicate: true
        }
        localStorage.setItem(
            constants.CLIENT_DETAIL_LOCAL_STORE,
            JSON.stringify(updatedClient)
        )
        setPrevClientDetails(updatedClient)
        setDisplayModal(false)
    }

    const interactionHandler = (interaction) => {
        if (interaction.name === ClientInteractions.DUPLICATE_FLAG) {
            return setDisplayModal(true)
        }
        sendInteractionsActionHandler({
            ...interaction,
            client_code: clientId,
            user_id: username,
            outcome: interaction.outcome,
            client_status: prevClientDetails.client_status,
            survey_name: constants.TARGET_LIST_SURVEY_NAME,
            survey_version: constants.TARGET_LIST_SURVEY_VERSION,
            village_id: villageId
        })
        showInteractionFeedbackModal(t('clientDetails.interactionRecorded'))
        if (interaction.name === ClientInteractions.ENROLL) {
            reEnrollmentHandler()
        } else if (!isClientInfoComplete(prevClientDetails)) {
            modifyHandler()
        }
    }

    const paymentHistoryRender =
        prevClientDetails && prevClientDetails.smss
            ? prevClientDetails.smss
                  ?.filter(isSupportedTransaction)
                  .map((transaction, idx) => (
                      <PaymentRow
                          key={idx}
                          country={country}
                          transaction={transaction}
                      />
                  ))
            : []

    const villageClientDetailsRender = (clientDetailsData) => {
        const {
            fullname,
            client_code: clientCode,
            sex: Genre,
            group_role: groupRole,
            saving_group_name: savingGroupName,
            zone,
            phone,
            reimbursement_choice: reimbursementChoice,
            village,
            age,
            duplicate,
            last_season_goal_item: lastSeasonGoalItem,
            recent_year_delivered: recentYearDelivered,
            recent_year_enrolled: recentYearEnrolled,
            balance
        } = clientDetailsData
        return (
            <>
                <div className="header-bar" id="header-bar">
                    <Container>
                        <TopBar back backNavigation={backNavigationHandler}>
                            {t('village.village')} {villageName}
                        </TopBar>
                    </Container>
                </div>
                <div
                    className="village-client-details-parent page-wrapper"
                    data-testid="client-details-page"
                >
                    <Container>
                        <Row>
                            <Col md={12}>
                                <ClientStatus
                                    status={
                                        duplicate
                                            ? ClientStatusEnum.DUPLICATE
                                            : ClientStatusEnum.POTENTIAL
                                    }
                                />
                            </Col>
                        </Row>
                        <Row className="content-center">
                            <Col md={4}>
                                <Card shadow className="height-100">
                                    <CardClientDetails
                                        className="village-prev--client-card-details"
                                        clientCode={clientCode}
                                        clientPhone={phone}
                                        modifyHandler={reEnrollmentHandler}
                                        buttonProps={{
                                            title: t('subscribe'),
                                            hide: false
                                        }}
                                        disableEdit={!canEnrollClientPackage}
                                        collapseMore={true}
                                        otherClientData={{
                                            [t('gender')]: Genre,
                                            [t('age')]: age || '-',
                                            [t('groupRole')]:
                                                groupRole || 'Non',
                                            [t('savingGroupName')]:
                                                savingGroupName || 'Non',
                                            [t('village.village')]:
                                                villageName || village,
                                            [t('zone')]: zone,
                                            [t('personalPhone')]:
                                                amountFormatter(phone),
                                            [t('reimbursementChoice')]:
                                                reimbursementChoice || '-',
                                            [t('village.registeredProducts')]:
                                                lastSeasonGoalItem || '-',
                                            [t('village.lastYearDelivered')]:
                                                recentYearDelivered || '-',
                                            [t('village.registrationDate')]:
                                                recentYearEnrolled || '-'
                                        }}
                                        clientPage={
                                            constants.CLIENT_TYPES
                                                .PREVIOUS_CLIENT
                                        }
                                        isClientDuplicate={
                                            prevClientDetails?.duplicate
                                        }
                                        clientStatus={
                                            prevClientDetails?.client_status
                                        }
                                        interactionHandler={interactionHandler}
                                        balance={balance}
                                    >
                                        {fullname}
                                    </CardClientDetails>
                                </Card>
                            </Col>
                        </Row>
                        {paymentHistoryRender.length > 0 && (
                            <Row
                                className="village-client-details-parent"
                                data-testid="client-details-payment"
                            >
                                <Col md={12}>
                                    <Card>
                                        <div className="village-client-details-padding village-payment--main">
                                            <div className="client-payment--history-parent">
                                                <h2 className="client-payment--history-header">
                                                    {t('paymentHistory')}
                                                </h2>
                                                {paymentHistoryRender}
                                            </div>
                                        </div>
                                    </Card>
                                </Col>
                            </Row>
                        )}
                        {displayVisitButton && (
                            <div className="visit-new-container">
                                <Button
                                    className="visit-new-btn"
                                    style="primary"
                                    onClick={openVisitPageHandler}
                                    data-testid="button-new-visit"
                                    id="btn-new-visit"
                                >
                                    {t('enrollment.newVisit')}
                                </Button>
                            </div>
                        )}
                    </Container>
                </div>
                <Modal
                    showModal={displayModal}
                    closeClickHandler={() => setDisplayModal(false)}
                    hideButtons
                    showCloseIconBtn
                    position="center"
                    className="duplicate-confirm-modal"
                    data-testid="mark-client-duplicate-modal"
                >
                    <ConfirmDuplicateFlagInteraction
                        clientCode={prevClientDetails?.client_code}
                        clientNames={prevClientDetails?.fullname}
                        handleOnClick={markClientAsDuplicate}
                    />
                </Modal>
            </>
        )
    }

    const singleClientLoading = (
        <Container>
            <Row>
                <Col md={12}>
                    <div className="loading-wrapper">
                        <p className="loading-text">
                            {t('fetchingPaymentLoader')}
                        </p>
                        <Spinner size="120" />
                    </div>
                </Col>
            </Row>
        </Container>
    )

    const villageClientDetailPage = singleClientLoader
        ? singleClientLoading
        : prevClientDetails && villageClientDetailsRender(prevClientDetails)

    return villageClientDetailPage
}
