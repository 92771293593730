import { Button, Container } from 'components'
import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { cardPaymentIcon, mobileMoneyPaymentIcon } from 'assets'
import variables from '../../scss/abstract/_variables.scss'
import { amountFormatter, constants, getConnectUser } from 'helpers'
import './enrollForm.scss'

type PaymentSelectionProps = {
    totalEnrollment: number
    onPaymentMethodSelected?: (paymentMethod: string) => void
    isOnPaymentPage?: React.Dispatch<React.SetStateAction<boolean>>
    btnText?: string
    headerTitle?: string
}

export const PaymentSelection = ({
    totalEnrollment,
    onPaymentMethodSelected,
    isOnPaymentPage,
    btnText,
    headerTitle
}: PaymentSelectionProps) => {
    const { t } = useTranslation()
    const [selectedMethod, setSelectedMethod] = React.useState('')
    const { country } = getConnectUser()

    const paymentSelectionHandler = () => {
        onPaymentMethodSelected?.(selectedMethod)
    }

    useEffect(() => {
        if (isOnPaymentPage) {
            isOnPaymentPage(true)
        }

        return () => {
            if (isOnPaymentPage) {
                isOnPaymentPage(false)
            }
        }
    }, [])

    return (
        <Container sm={12}>
            <Container>
                <div className="product--selection-header">
                    <h2 className="product--selection-title">
                        {headerTitle || t('enrollment.selectPayment')}
                    </h2>
                </div>
            </Container>
            <Container className="payment-selection-container">
                <div className="payment-summary">
                    <div className="payment-progress"></div>
                    <p className="payment-total">
                        {t('total')}:{' '}
                        <b>{amountFormatter(totalEnrollment, country)}</b>
                    </p>
                </div>
                <div className="enrollment-button">
                    <div className="payment-methods-container">
                        <div
                            className={`payment-method-btn ${
                                selectedMethod ===
                                constants.PAYMENT_OPTIONS.SCRATCH_CARD
                                    ? 'payment-method-btn--selected'
                                    : ''
                            }`}
                            onClick={() => {
                                setSelectedMethod(
                                    constants.PAYMENT_OPTIONS.SCRATCH_CARD
                                )
                            }}
                            aria-label="scratch-card-button"
                        >
                            {cardPaymentIcon({
                                width: '55',
                                height: '55',
                                color: variables.primaryColor,
                                backgroundColor: ''
                            })}
                            <p>{t('enrollment.scratchCard')}</p>
                        </div>
                        <div
                            className={`payment-method-btn ${
                                selectedMethod ===
                                constants.PAYMENT_OPTIONS.MOBILE_MONEY
                                    ? 'payment-method-btn--selected'
                                    : ''
                            }`}
                            onClick={() => {
                                setSelectedMethod(
                                    constants.PAYMENT_OPTIONS.MOBILE_MONEY
                                )
                            }}
                            aria-label="mobile-money-button"
                        >
                            {mobileMoneyPaymentIcon({
                                width: '55',
                                height: '55',
                                color: variables.primaryColor,
                                backgroundColor: ''
                            })}
                            <p>{t('enrollment.mobileMoney')}</p>
                        </div>
                    </div>
                    {selectedMethod && (
                        <div className="client-details--bottom-button-parent">
                            <Button
                                className="client-details--bottom-button"
                                style="primary"
                                onClick={paymentSelectionHandler}
                                disable={!selectedMethod}
                            >
                                {btnText || t('continue')}
                            </Button>
                        </div>
                    )}
                </div>
            </Container>
        </Container>
    )
}
